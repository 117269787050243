* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  scroll-margin-block: 0px;
}
body {
  width: 100%;
  background-color: rgb(243, 241, 238);
  font-family: "Times New Roman", Times, serif;
  height: 100%;
}

header {
  width: 100%;
  position: fixed;
  left: 0;
  border-bottom: #000000 solid 2px;
  z-index: 3;
  background-color: rgb(243, 241, 238);
}

nav {
  display: flex;

  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.nav_menu {
  flex: 1;
  margin-right: 30%;
}

.nav_logo {
  flex: 1;
  margin-left: 35px;
  font-weight: bold;
  font-size: 30px;
}
.nav_list_grid {
  list-style-type: none;
  margin-left: auto;
  flex: 2;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  right: 10px;
  position: relative;
  background-color: #80bff7;
  padding: 10px;
  border-radius: 90;
  z-index: 2;
}

.nav_item {
  text-align: center;
  font-weight: bold;
  transform: scale(1.2);
}

.home_container_grid {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 60% 20%;
  overflow: auto;
}

.social,
.home,
.empty {
  padding: 10px;
}

.filler {
  width: 100%;
  height: 70px;
}

.social {
  height: 600px;
  text-align: center;
  margin-top: 60%;
}

.social_icons {
  position: fixed;
  padding-left: 8%;
  list-style: none;
}

.linkedin,
.facebook,
.email {
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.home_container {
  display: flex;
  flex-direction: row;
  margin-top: 15%;
  text-align: center;
}

.home_social {
  flex: 1;
}

.img {
  width: 30%;
}

.aj_image {
  padding-left: 40px;
  height: 300px;
  width: auto;
  border-radius: 190 90 380;
}

.titlu,
.subtitle {
  text-align: justify;

  font-size: 40px;
}

.subtitle {
  color: rgb(173, 171, 171);
  word-spacing: normal;
  letter-spacing: normal;
}

.paragraf {
  margin-top: 20px;

  text-align: justify;
  font-size: 22px;
  word-spacing: normal;
  letter-spacing: normal;
}

.home_buttons {
  display: flex;
  margin: 10%;
  flex-direction: row;
  margin-left: 25%;
}

.button_downloadcv,
.contact_me {
  padding: 15px;
  margin-left: 10px;
  text-align: center;
  margin-left: 10%;
  width: 100%;
  max-width: max-content;
  cursor: pointer;
  background-color: rgb(128, 191, 247);
  border-radius: 40px;
  font-size: 20px;
}

.aligned {
  display: flex;
  margin-left: 20px;
  flex-direction: row;
  text-align: right;
  align-items: center;
  transform: scale(1);
  padding-right: 20px;
  flex: 1;
}

.dow {
  flex: 2;

  transform: scale(0.5);
  margin-left: 0px;
}

.experience {
  height: 400px;
  width: 100%;
  display: grid;
  grid-template-columns: 20% 60% 20%;
}

.about {
  margin-top: 10%;
  margin-left: 15%;
  text-align: justify;
  font-size: 22px;
  word-spacing: normal;
  letter-spacing: normal;
  grid-column: 2;
}

.about > h1 {
  margin-bottom: 20px;
  font-size: 40px;
}

.jobs {
  display: grid;
  grid-template-columns: 20% 60% 20%;
}
#list_jobs {
  list-style-type: none;
  grid-column: 2;
  margin-left: 50%;
  text-align: justify;
  font-size: 22px;
  word-spacing: normal;
  letter-spacing: normal;
  padding-left: 1.6%;
  margin-bottom: 5%;
}

#list_jobs,
p {
  padding-bottom: 5px;
}

.bunch {
  margin-bottom: 5%;
  display: flex;
  flex-direction: row;
  align-content: center;
}

.position {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: start;
  align-content: start;
  position: relative;

  font: bold;
  font-size: 30px;
}

.desc {
  font-size: 15px;
  width: 50%;
}

.container_journey {
  display: flex;
  flex-direction: row;
}

.circle {
  position: relative;
  width: 50%;
  height: 20px;
  width: 20px;
  z-index: 1;
  border-radius: 50%;
  border: #000000 solid;
  background-color: antiquewhite;
  right: 5%;
}

.line {
  display: block;
  width: 5px;
  background: #000000;
  position: relative;
  right: 52%;
  height: 120%;
}

.experience__le {
  margin-top: 10%;
  margin-left: 15%;
  grid-column: 2;
  font-size: 40px;
  margin-bottom: 10%;
}

#list_skills {
  list-style-type: none;
  grid-column: 2;
  margin-left: 20%;
  text-align: justify;
  font-size: 22px;
  word-spacing: normal;
  letter-spacing: normal;
  padding-left: 1.6%;
  margin-bottom: 5%;
}

#list_skills,
p {
  padding-bottom: 5px;
}

.row {
  display: flex;
  flex-direction: row;
}

.bar {
  border-radius: 5%;
  border: solid rgb(124, 189, 124);
  background-color: rgb(124, 189, 124);
  width: 10%;
  height: 10px;
  margin: 5px;
}

h3 {
  margin-top: 5%;
}

.scroll_down_icon {
  position: fixed;
  right: 15%;
  bottom: 10%;
  width: auto;
}

.imgdown {
  width: 50px;
}

.imgdownhide {
  width: 0px;
}
